import {defineStore} from 'pinia'

export const useBooks = defineStore('books', {
	state: () => {
		return {
			books: [
				{directory: "Die-kleine-Raupe-Nimmersatt", pages: 14 },
				{directory: "Kennst-Du-Petterson-und-Findus", pages: 9},
				{directory: "Wo-ist-Petterson", pages: 20},
				{directory: "Frida-will-auch", pages: 9},
				{directory: "Unser-Tag", pages: 9},
				{directory: "Der-Mond-ist-aufgegangen", pages: 8},
				{directory: "Billie-und-Joko-brauchen-deine-Hilfe", pages: 8},
				{directory: "Bin-ich-wütend-oder-froh", pages: 8},
				{directory: "Tiere", pages: 29},
				{directory: "Fidi-und-Bibo-finden-Freunde", pages: 8},
				{directory: "Tür-auf-Tür-zu", pages: 14},
				{directory: "Ängstlich-Wütend-Fröhlich-sein", pages: 19},
				{directory: "Das-NEINhorn", pages: 25},
				{directory: "Mach-die-Tür-auf-Jonathan", pages: 13},
				{directory: "Karius-und-Baktus", pages: 24},
				{directory: "Bruno-findet-neue-Freunde", pages: 11},
			]
		}
	}
});
