<template>
	<div class="center" v-if="mode == 'start'">
		<BabyButton :handler="startQuiz" class="startButton">Neu</BabyButton>
	</div>
	<div class="center" v-if="mode == 'finish'">
		<!--{{ lives }} / 3 Leben -->
		<BabyButton :handler="startQuiz" class="restartButton">Nochmal</BabyButton>
	</div>
	<div class="question" v-if="mode != 'start' && mode != 'finish'">
		<progress value="20" max="100">42%</progress>
		<BabyButton :handler="playQuestion" class="questionButton">🔉</BabyButton>
		<BabyButton v-for="(option, key) in question.options" :key="key" :handler="() => {tryOption(key)}" class="answerButton">{{ option  }}</BabyButton>
	</div>
</template>
<script>
import { useQuiz } from '@/stores/quiz.js'
import BabyButton from '@/components/BabyButton.vue'
// player store?
// quiz store
export default {
	name: 'Quiz',
	setup() {
		return {
			store: useQuiz(),
		}
	},
	data() {
		return {
			mode: 'start', // 0, 1, 2; 'finish'
			lives: 3,
			question: undefined,
			length: undefined,
		}
	},
	methods: {
		startQuiz() {
			this.length = this.store.newQuiz();
			this.mode = 0;
			this.question = this.store.getQuestion(this.mode);
			this.playQuestion()
		},
		playQuestion() {
			var audio = document.getElementById("player");
			audio.src = "";
			var startAudio = () => {
				var playProm = audio.play();
				var debug = document.getElementById("debug");
				playProm.then(result => {
					//debug.innerHTML = "Result: " + result
					console.log(result);
				}, error => {
					//debug.innerHTML += "Ev before Error: " + ev.isTrusted + "<br/>"
					debug.innerHTML += "Error: " + error + "<br/>"
					debug.style.display = "block"
				});
			}
			audio.addEventListener("canplaythrough", startAudio);
			audio.src = this.question.question.sound
			audio.autoplay = true;
			audio.load(); // for iOS...
		},
		tryOption(key) {
			console.log(key)
			console.log(this.question.solution)
			if(key == this.question.solution) {
				this.nextQuestion()
			}
		},
		nextQuestion() {
			this.mode++
			if(this.mode < this.length) {
				// next question
				this.question = this.store.getQuestion(this.mode);
				this.playQuestion()
			} else {
				// finish
				this.mode = "finish"
				this.question = undefined
				this.length = undefined
			}
		}
	},
	components: {
		BabyButton
	}
}
</script>
<style scoped>
.center {
	height: 100vh;
	display:flex;
	justify-content:center;
	align-items: center;
}
.startButton, .restartButton {
	font-size: 10em;
	padding: 4vh;
}
.question {
	margin: 2vh;
	height: 96vh;
	display: grid;
	grid-template-rows: 5vh 70vh 1fr;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 4vmax;
	align-items: center;
	font-size: 10em;
}
.questionButton {
	grid-column: 1 / span 3;
	justify-self: center;
	padding: 4vh;
	font-size: 2em;
}

.question progress {
	grid-column: 1 / span 3;
	appearance: none;
	height: 5vh;
	color: var(--orange); /* IE */
	border-radius: 1vh;
}

progress::-webkit-progress-value, progress::-moz-progress-bar {
	background: var(--orange);
	border-radius: 1vh;
}

</style>
