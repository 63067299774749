<template>
	<div class="alphabet">
		<BabyButton class="card random-background" :handler="play" v-for="letter in abc" :key="letter">{{letter}}</BabyButton>
	</div>
</template>

<script>
import BabyButton from '@/components/BabyButton.vue'
import screen from '@/assets/fullscreen.helper.js'
var audio;

export default {
	name: 'Abc',
	data() {
		return {
			abc: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
		}
	},
	methods: {
		play(ev) {
			screen.requestFullscreen();
			var letter = ev.target.innerText.toLowerCase();

			audio = new Audio();
			audio.addEventListener("canplaythrough", function(){
				//audio.play();
				var playProm = audio.play();
				var debug = document.getElementById("debug");
				playProm.then(result => {
					debug.innerHTML = "Result: " + result
				}, error => {
					debug.innerHTML = "Error: " + error
				});
				//t2=setTimeout(reset, 1000 * audio.duration);
			});
			audio.src = 'static/abc/'+ letter + '.mp3';
			audio.load(); // for iOS...
		}
	},
	components: {
		BabyButton
	}
}
</script>

<style>
.alphabet {
	height: 98vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-items: stretch;
	align-content: stretch;
}

.card {
	margin: 1vh 1vw;
	flex-basis: content;
	min-width: 10vw; /* 8vw for 3 rows */
	display: flex;
	flex-grow: 1;
	justify-content: space-around;
	align-items: center;
	font-size: 18vh;
	line-height: 18vh;
}

.random-background {
	color: var(--light-grey);
}
</style>
