import { createRouter, createWebHashHistory } from 'vue-router'
import Numbers from '../views/Numbers.vue'
import Abc from '../views/Abc.vue'
import Books from '../views/Books.vue'
import Book from '../views/Book.vue'
import quiz from '../views/quiz.vue'

const routes = [
	{
		path: '/',
		name: 'Numbers',
		component: Numbers
	},
	{
		path: '/abc',
		name: 'Abc',
		component: Abc
	},
	{
		path: '/books',
		name: 'Books',
		component: Books
	},
	{
		path: '/book/:dir/:page',
		name: 'Book',
		component: Book
	},
	{
		path: '/quiz',
		name: 'quiz',
		component: quiz
	},
//	{
//		path: '/about',
//		name: 'About',
//		// route level code-splitting
//		// this generates a separate chunk (about.[hash].js) for this route
//		// which is lazy-loaded when the route is visited.
//		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
