<template>
	<div class="center">
		<BabyButton :handler="increase" class="numberButton">{{counter}}</BabyButton>
	</div>
</template>

<script>
import BabyButton from '@/components/BabyButton.vue'
import screen from '@/assets/fullscreen.helper.js'
var audio;

export default {
	name: 'Numbers',
	data() {
		return {
			counter: 0,
		}
	},
	methods: {
		increase() {
			screen.requestFullscreen();
			this.counter++;
			if(this.counter > 20) {
				this.counter=0;
			}
			audio = new Audio('static/numbers/'+ this.counter + '.mp3');
			audio.addEventListener("canplaythrough", function(){
				//audio.play();
				var playProm = audio.play();
				var debug = document.getElementById("debug");
				playProm.then(result => {
					debug.innerHTML = "Result: " + result
				}, error => {
					debug.innerHTML = "Error: " + error
				});
			});
			audio.load(); // for iOS...
		}
	},
	components: {
		BabyButton
	}
}
</script>
<style scoped>
.center {
	height: 100%;
	display:flex;
	justify-content:center;
	align-items: center;
}
.numberButton {
	width: 60vw;
	height: 40vh;
	font-size: 28vh;
	line-height: 38vh;
}
</style>
