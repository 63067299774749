import {defineStore} from 'pinia'

export const useQuiz = defineStore('quiz', {
	state: () => {
		return {
			quiz: [],
		}
	},
	actions: {
		newQuiz() {
			this.quiz = [
				{
					question: {
						sound: "static/numbers/2.mp3"
					},
					options: [
						"1",
						"2",
						"3",
					],
					solution: 1,
				},
				{
					question: {
						sound: "static/numbers/12.mp3"
					},
					options: [
						"5",
						"2",
						"12",
					],
					solution: 2,
				},
				{
					question: {
						sound: "static/numbers/17.mp3"
					},
					options: [
						"17",
						"8",
						"16",
					],
					solution: 0,
				},
				{
					question: {
						sound: "static/abc/y.mp3"
					},
					options: [
						"B",
						"Y",
						"P",
					],
					solution: 1,
				},

			]
			return this.quiz.length;
		},
		getQuestion(key) {
			return this.quiz[key];
		},
	},
});
