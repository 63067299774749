<template>
	<div class="book" ref="book" :style="`background-image: url('static/books/${this.$route.params.dir}/${this.$route.params.page}.jpg')`">
		<BabyButton class="button" v-if="showButton" :handler="nextPage">Weiter</BabyButton>
	</div>
</template>

<script>
import { useBooks } from '@/stores/books.js'
import BabyButton from '@/components/BabyButton.vue'
import screen from '@/assets/fullscreen.helper.js'

export default {
	name: 'Books',
	setup() {
		return {
			store: useBooks(),
		}
	},
	data() {
		return {
			pages: 5,
			showButton: false,
		}
	},
	methods: {
		startAudio(page) {
			this.showButton = false;
			var audio = document.getElementById("player");
			audio.src = "";
			var startAudio = () => {
				var playProm = audio.play();
				var debug = document.getElementById("debug");
				playProm.then(result => {
					//debug.innerHTML = "Result: " + result
					console.log(result);
				}, error => {
					//debug.innerHTML += "Ev before Error: " + ev.isTrusted + "<br/>"
					debug.innerHTML += "Error: " + error + "<br/>"
					debug.style.display = "block"
				});
				setTimeout(() => {
					this.showButton = true
				}, 1000 * audio.duration);
			}
			audio.addEventListener("canplaythrough", startAudio);
			audio.src = 'static/books/'+ this.$route.params.dir + '/'+ page +'.mp3'
			audio.autoplay = true;
			audio.load(); // for iOS...
		},
		nextPage() {
			screen.requestFullscreen();
			var maxPages=1;
			// find the number of page sfor this book
			for (var i=0; i<this.store.books.length; i++) {
				if(this.store.books[i].directory == this.$route.params.dir) {
					maxPages = this.store.books[i].pages;
				}
			}

			// if the next page (0 based) is still lower than maxPages (1 based)
			if(parseFloat(this.$route.params.page) + 1 < maxPages) {
				this.$router.push({name: "Book", params: {dir: this.$route.params.dir, page: parseFloat(this.$route.params.page)+1}})
			} else {
				this.$router.push({name: "Books"});
			}
		}
	},
	components: {
		BabyButton
	},
	mounted(){
		this.startAudio(this.$route.params.page);
	},
	beforeRouteUpdate(to) {
		this.startAudio(to.params.page);
	},
}
</script>
<style scoped>
.book {
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
.button {
	padding: 1vw 2vw;
	position: absolute;
	bottom: 5vh;
	right: 5vw;
	font-size: 56px;
	line-height: 1.8;
}
</style>
