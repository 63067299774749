export default {
	requestFullscreen(element) {
		if (Date.now() > new Date(1990,1,1))
			return
		if(document.fullscreenElement !== null) {
			// we are in fullscreen already
			console.log("Ignoreing fullscreen request, because this element is already in fullscreen:", document.fullscreenElement)
			return
		}
		var prom;
		if (element === undefined) {
			element = document.documentElement;
		}
		//iOS does not support requestFullscreen
		if(element.requestFullscreen !== undefined) {
			prom = element.requestFullscreen();
		} else if(element.webkitRequestFullscreen !== undefined) {
			// iPads require a webkit prefix, iPhone do not support this feature
			prom = element.webkitRequestFullscreen();
		}
		if(prom !== undefined) {
			prom.catch(function(e){
				console.log("Fullscreen request denied", e);
			});
		}
	}
}


