<template>
	<div class="booklist">
		<BabyButton v-for="book in store.books" :key="book.directory" :handler="() => {openBook(book.directory)}">
			<img class="titleImg" :src="'static/books/'+book.directory+'/0.jpg'">
		</BabyButton>
	</div>
</template>

<script>
import { useBooks } from '@/stores/books.js'
import BabyButton from '@/components/BabyButton.vue'
import screen from '@/assets/fullscreen.helper.js'

export default {
	name: 'Books',
	setup() {
		return {
			store: useBooks(),
		}
	},
	methods: {
		openBook(dir) {
			screen.requestFullscreen();
			this.$router.push({name: "Book", params: {dir: dir, page: 0}});
		}
	},
	components: {
		BabyButton
	}
}
</script>
<style scoped>
.booklist {
	width: 80vw;
	display: grid;
	grid-template-columns: repeat(4, 18.5vw);
	grid-auto-rows: 18.5vw;
	gap: 2vw;
}
.titleImg {
	height: 100%;
	width: 100%;
}
</style>
