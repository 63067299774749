<template>
	<!--<div ref="button" class="button" @mouseover.stop="handle" @clickCapture.stop="handle" :style="`background-color:${background}`"><slot></slot></div>-->
	<!--<div ref="button" class="button" @touchstart.prevent.stop="handle" @pointerenter.prevent.stop="handle" :style="`background-color:${background}`"><slot></slot></div>-->
	<div ref="button" class="button" @touchstart.prevent.stop="handle" @pointerdown.prevent.stop="handle" :style="`background-color:${background}`"><slot></slot></div>
</template>
<script>
export default {
	name: 'BabyButton',
	data() {
		return {
			locked: false,
			hasMouse: window.matchMedia('(pointer:fine)').matches,
			background: 'var(--orange)',
		}
	},
	methods: {
		computeBackground() {
			// FIXME: when does it happen that $refs.button is undefined?!?
			if (this.$refs.button && this.$refs.button.classList.contains("random-background")) {
				var x = Math.floor(Math.random() * 6 + 1)
				//el.style.setProperty("background-color", "var(--color-0" + x + ")");
				this.background = "var(--color-0" + x + ")";
			}
		},
		handle(ev) {
			if(this.locked) {
				return;
			}
			// do not handle events that are not initiated by the user
			// check for === false to abort only if the browser supports the attribute
			console.log(ev)
			// ignore mouseover events if a mouse is present
			if(this.hasMouse) {
				if(ev.type === "mouseover" || ev.type === "pointerover") return;
			}
			var debug = document.getElementById("debug");
			debug.innerHTML += "Event.type: " + ev.type + "<br/>"
			debug.innerHTML += "Event.isTrusted: " + ev.isTrusted + "<br/>"
			if(!ev.isTrusted) {
				return;
			}
			var el = ev.target;
			this.locked = true;
			el.classList.add('unpress');
			this.handler(ev);
			setTimeout(() => {
				this.locked = false;
				el.classList.remove('unpress');
				this.computeBackground();
			}, this.duration)
		}
	},
	props: {
		handler: { type: Function },
		duration: { type: Number, default: 1000 },
	},
	mounted() {
		this.computeBackground();
	},
}
</script>
<style>
.button {
	text-align: center;
	/*background-color: var(--orange);*/
	color: var(--font-color);
	box-shadow: 0px 2px 1vh var(--shadow);
	border-radius: 1vh;
	user-select: none;
}

@keyframes unpress {
	from {
		box-shadow: 0px 2px 1vh hsla(30, 100%, 20%, 0.0);
		background-position: 50% 0%;
	}
	to {
		box-shadow: 0px 2px 1vh hsla(30, 100%, 20%, 1.0);
		background-position: 50% 100%;
	}
}
.unpress {
	background: linear-gradient(0deg, var(--orange) 0%, var(--orange) 50%, var(--dark-orange) 50%, var(--dark-orange) 100%);
	background-size: 201% 201%;
	background-position: 50% 100%;
	animation-name: unpress;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-timing-function: ease-in;
}
.random-background {
	color: var(--light-grey);
}
</style>
